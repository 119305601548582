import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Button } from '@material-ui/core';
import { DescriptionCta } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  dsgvo: {
    '&& a': {
      color: theme.palette.primary.main,
    },
    '&& h1, h2, h3': {
      marginBottom: 10,
      marginTop: 30,
    },
    '&& p': {
      marginBottom: 20
    },
    '&& ul': {
      marginLeft: 35,
      marginTop: 25,
      marginBottom: 35,
    },
    '&& li': {
      marginBottom: 15,
    }
  }
}));

import { dsgvo } from '../../assets/data';

const Datenschutz = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop} >
        <>
        <DescriptionCta
          title="Datenschutzerklärung"
          subtitle="Stand: 3. November 2021"
          primaryCta={
            <Button 
              variant="outlined" 
              color="primary" 
              size="large"
              onClick={() => {
                window.print();
              }}>
              Drucken
            </Button>
          }
          align={'left'}
          titleProps={{
            className: classes.fontWeightBold,
            color: 'textPrimary',
          }}
          subtitleProps={{
            color: 'textSecondary',
          }}
        />
        
        <Divider className={classes.divider} />

        <div className={classes.dsgvo} dangerouslySetInnerHTML={{ __html: dsgvo.html }} />
        
        </>
      </Section>
      <Divider />
    </div>
  );
};

export default Datenschutz;
